import { LinkType } from '@Types'

export const MOBILE_MENU_LINKS: LinkType[] = [
    {
        title: 'reviews',
        url: `${process.env.NEXT_PUBLIC_ORIGIN_URL}/#reviews`,
        id: '1.2',
    },
    {
        title: 'speakers',
        url: `${process.env.NEXT_PUBLIC_ORIGIN_URL}/#speakers`,
        id: '1.3',
    },
]
