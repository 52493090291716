import React, { useEffect, useState } from 'react'
import cx from 'classnames'
import { useRouter } from 'next/router'
import Link from 'next/link'
import { Social } from '@Components/Social/Social'
import { useDelayUnmount } from '@Utils/delayUnmount'
import { SocialSize } from '@Types'
import { MOBILE_MENU_LINKS } from '@Constants/mobileLinks'

import styles from './MobileMenu.module.scss'

export const MobileMenu: React.FC<{
    isRendered: boolean
    setisRendered: (boolean: boolean) => void
    shouldShowMobileMenu: boolean
}> = ({ isRendered, setisRendered, shouldShowMobileMenu }) => {
    const [isAboutBlockMounted, setAboutBlockMounted] = useState(false)
    const shouldShowAbout = useDelayUnmount(isAboutBlockMounted, 250)

    const { push } = useRouter()

    useEffect(() => {
        const body = document.querySelector('body')

        if (!body) {
            return
        }

        if (isRendered) {
            body.style.overflow = 'hidden'
            return
        }

        body.style.overflow = 'auto'
    }, [isRendered])

    const closeMenu = (): void => {
        setisRendered(false)
    }

    const goToPage = async (url: string) => {
        await push(url)
        closeMenu()
    }

    const handleAboutBlockClick = () => {
        setAboutBlockMounted(!isAboutBlockMounted)
    }
    const renderMobileLinks = () => {
        return MOBILE_MENU_LINKS.map((item) => (
            <Link key={item.id} href={item.url}>
                <a
                    className={styles.link}
                    onClick={(e) => {
                        e.preventDefault()
                        goToPage(item.url)
                    }}
                >
                    <h1>{item.title}</h1>
                </a>
            </Link>
        ))
    }

    return (
        <>
            {shouldShowMobileMenu && (
                <div
                    className={cx(styles.mobileMenuWrapper, {
                        [styles.opened]: isRendered,
                    })}
                >
                    <div className={styles.mobileLinks}>
                        <div
                            className={styles.link}
                            onClick={handleAboutBlockClick}
                        >
                            <h1>about</h1>

                            {shouldShowAbout && (
                                <p
                                    className={cx(styles.aboutContent, {
                                        [styles.showAbout]: isAboutBlockMounted,
                                    })}
                                >
                                    Nowadays the web suggests you tons of
                                    designs, around 10% of which could be called
                                    brilliant, and only 1% of those ten are
                                    recognizable and famous. Dark Archive is a
                                    non-profit project with the mission of
                                    finding, collecting and promoting great
                                    design ideas. <br /> <br />
                                    Our selectees are famous designers who gave
                                    life to works that we encounter daily. These
                                    experts will tell us what influenced their
                                    creativity and helped in shaping their own
                                    style, what helps them now to wake up every
                                    morning, full of inspiration and energy to
                                    bring amazing pieces into being.
                                </p>
                            )}
                        </div>
                        {renderMobileLinks()}
                    </div>
                    <div className={styles.socialsAndCopyright}>
                        <div className={styles.mobileSocials}>
                            <Social
                                size={SocialSize.Medium}
                                link="https://twitter.com/dark_flowmapp"
                            >
                                <img src="/img/twitter.svg" alt="Twitter" />
                            </Social>
                            <Social
                                size={SocialSize.Medium}
                                link="https://www.instagram.com/dark.flowmapp/"
                            >
                                <img src="/img/instagram.svg" alt="Instagram" />
                            </Social>
                        </div>
                        <div className={styles.mobileCopyright}>
                            <h4 className={styles.madeBy}>
                                MADE BY &nbsp;
                                <a
                                    href="https://www.flowmapp.com/"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    FLOWMAPP
                                </a>
                            </h4>
                            <h4 className={styles.rights}>
                                © 2021 ALL RIGHTS RESERVED
                            </h4>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}
