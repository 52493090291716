import React, { ReactNode } from 'react'
import cx from 'classnames'
import styles from './Button.module.scss'

export const Button: React.FC<{
    children: ReactNode
    className?: string
    onClick?: (e: React.MouseEvent) => void
}> = ({ children, onClick, className }) => {
    return (
        <button onClick={onClick} className={cx(styles.button, className)}>
            {children}
        </button>
    )
}
