import { useRouter } from 'next/router'

import styles from './PreviewNotify.module.scss'

const PreviewNotify = ({ preview }: { preview?: boolean }) => {
    const router = useRouter()

    if (!preview) {
        return null
    }

    async function exitPreviewMode() {
        const res = await fetch('/api/exit-preview').catch((err) =>
            console.error(err)
        )

        if (res) {
            window.close()
        }
    }

    return (
        <div className={styles.wrapper}>
            <p>Preview enabled!</p>
            <button onClick={() => exitPreviewMode()}>Turn off</button>
        </div>
    )
}

export default PreviewNotify
