import React from 'react'
import cx from 'classnames'
import { Direction } from '@Types'
import styles from './PaginationButton.module.scss'

export const PaginationButton: React.FC<{
    direction?: Direction
    className?: string
    onClick?: () => void
}> = ({ direction = Direction.Left, className, onClick }) => {
    return (
        <button
            onClick={onClick}
            className={cx(styles.paginationButton, className)}
        >
            <svg
                className={cx(styles[direction], styles.fill)}
                width="12"
                height="10"
                viewBox="0 0 12 10"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path d="M3.11268 5.81429V5.91429L7.16901 10H4.87324L0 5.02857L0 4.97143L4.87324 0H7.16901L3.11268 4.1V4.2L12 4.2V5.81429L3.11268 5.81429Z" />
            </svg>
        </button>
    )
}
