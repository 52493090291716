export enum Direction {
    Up = 'up',
    Left = 'left',
    Right = 'right',
}

export enum LikeType {
    Regular = 'regular',
    Project = 'project',
}

export enum HTMLTag {
    Link = 'A',
    Button = 'BUTTON',
}

export interface Speaker {
    id: number
    name: string
    position: string
    about: string
    review?: Review
    image: Image
    slug: string
    links: Link[]
    empty?: boolean
    recommendations: Recommendation[]
}

export interface SpeakerInfo {
    name: string
    position: string
    image: Image
}

export interface EmptySpeaker {
    empty: boolean
    id: number
}

export enum SpeakerSize {
    Small = 'small',
    Medium = 'medium',
}

export enum SocialShare {
    Facebook = 'facebook',
    Twitter = 'twitter',
    Instagram = 'instagram',
    Behance = 'behance',
    Email = 'email',
}

export enum SocialSize {
    Medium = 'medium',
    Small = 'small',
}

interface ReviewCardReviewInfo {
    image: Image
    text: string
    id: number
}

interface Formats {
    thumbnail: Image
    medium?: Image
}

export interface Image {
    id: number
    name: string
    url: string
    formats?: Formats
}

interface Link {
    id: string
    link: string
    linkIcon: Image
}

export interface IReviewCard {
    authorLink: string;
    author: string
    id: string
    info: ReviewCardReviewInfo[]
    likes: Likes[]
    number: string
    title: string
}

interface Likes {
    id: string
    userId: string
}

export interface Review {
    id: number
    mainImage: Image
    title: string
    date: string
    link: string
    number: string
    authorName: string
    speaker: Speaker
    likes: Likes[]
    links: Link[]
    reviewCards: IReviewCard[]
}

export interface LinkType {
    title: string
    url: string
    id: string
    blank?: boolean
}

export interface Recommendation {
    id: number
    name: string
    imgSrc: string
    link: string
}

export interface IMainReviewer {
    id: number
    slug: string
    date: string
    number: string
    reviewTitle: string
    speakerName: string
    speakerPosition: string
    reviewSubtitle: string
    aboutSpeaker: string
    speakerPhoto: Image
    reviewImage: Image
}

export interface QuoteLink {
    id: number
    name: string
    link: string
    network?: null
}

export enum SocialsSharingTypes {
    Article = 'article',
    Quote = 'quote',
}

export interface IPage {
    id: number
    metaDescription: string
    metaImage: Image
    metaTitle: string
    slug: string
    title: string
}
