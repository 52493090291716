import React from 'react'
import Link from 'next/link'
import { PaginationButton } from '@Components/PaginationButton/PaginationButton'
import { Direction, LinkType } from '@Types'
import { LINKS } from '@Constants/footerLinks'
import styles from './Footer.module.scss'
import { scrollTo } from '@Utils/scrollTo'

export const Footer: React.FC = () => {
    const renderLinks = (links: LinkType[]) => {
        return links.map((link) => {
            if (link.blank) {
                return (
                    <a
                        key={link.id}
                        href={link.url}
                        className={styles.link}
                        target="_blank"
                        rel="noreferrer"
                    >
                        <h4>{link.title}</h4>
                    </a>
                )
            }
            return (
                <Link key={link.id} href={link.url}>
                    <a className={styles.link}>
                        <h4>{link.title}</h4>
                    </a>
                </Link>
            )
        })
    }

    return (
        <div className={styles.footerWrapper}>
            <div className={styles.logoWrapper}>
                <img src="/img/Footer/logo.svg" alt="Dark.Archive" />
            </div>
            <div className={styles.descAndLinks}>
                <div className={styles.description}>
                    <h4>
                        Collection of outstanding designs, carefully selected by
                        well-known professionals
                    </h4>
                </div>
                <div className={styles.linksWrapper}>
                    <div className={styles.links}>
                        {LINKS.map((linksColumn, index) => (
                            <div key={index} className={styles.linksGroup}>
                                {renderLinks(linksColumn)}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className={styles.copyright}>
                <div className={styles.copyrightTitle}>
                    <h4>© 2021 ALL RIGHTS RESERVED</h4>
                </div>
                <div className={styles.paginationButtonWrapper}>
                    <PaginationButton
                        onClick={() => scrollTo(0)}
                        direction={Direction.Up}
                    />
                </div>
            </div>
        </div>
    )
}
