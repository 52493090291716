import { useEffect } from 'react'
import { HTMLTag } from 'types'

interface CustomMouseEvent extends MouseEvent {
    path: Node[]
}

export default function useCustomCursor() {
    useEffect(() => {
        window.onload = () => {
            document.body.onmousemove = (e) => {
                document.documentElement.style.setProperty(
                    '--x',
                    e.clientX + window.scrollX + 'px'
                )
                document.documentElement.style.setProperty(
                    '--y',
                    e.clientY + 'px'
                )
            }
        }
        function watchCursorStyles(ev: CustomMouseEvent) {
            const path = ev.path as HTMLElement[]

            const cursor = window.document.getElementById('invertedcursor')

            if (
                path.find(
                    (el) =>
                        el.tagName === HTMLTag.Link ||
                        el.tagName === HTMLTag.Button
                ) &&
                cursor
            ) {
                cursor.style.maxWidth = '20px'
                cursor.style.maxHeight = '20px'
            }

            if (
                !path.find(
                    (el) =>
                        el.tagName === HTMLTag.Link ||
                        el.tagName === HTMLTag.Button
                ) &&
                cursor
            ) {
                cursor.style.maxWidth = '15px'
                cursor.style.maxHeight = '15px'
            }
        }
        document.addEventListener(
            'mouseover',
            watchCursorStyles as EventListenerOrEventListenerObject
        )
        return () =>
            document.removeEventListener(
                'mouseover',
                watchCursorStyles as EventListenerOrEventListenerObject
            )
    }, [])
}
