import React, { ReactNode } from 'react'
import cx from 'classnames'
import { SocialSize } from '@Types'
import styles from './Social.module.scss'

export const Social: React.FC<{
    children: ReactNode
    size?: SocialSize.Medium | SocialSize.Small
    link?: string
    onClick?: () => void
}> = ({ children, size = SocialSize.Medium, link = '#', onClick }) => {
    return (
        <a
            href={link}
            className={cx(styles.socialLink, styles[size])}
            target="_blank"
            rel="noreferrer"
        >
            <div className={styles.content}>{children}</div>
        </a>
    )
}
