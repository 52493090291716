import '@Styles/globals.scss'
import type { AppProps } from 'next/app'
import { Header } from '@Components/Layout/Header/Header'
import { Footer } from '@Components/Layout/Footer/Footer'
import { useEffect } from 'react'
import { v4 as uuidv4 } from 'uuid'
import Cookies from 'js-cookie'
import PreviewNotify from '@Components/PreviewNotify/PreviewNotify'
import useCustomCursor from '@Hooks/useCustomCursor'
function MyApp({ Component, pageProps }: AppProps) {
    useEffect(() => {
        const isAuth = Cookies.get('undribbbleId')

        if (!isAuth) {
            Cookies.set('undribbbleId', uuidv4())
        }
    }, [])

    useCustomCursor()

    return (
        <>
            <PreviewNotify preview={pageProps.preview} />
            <div id="invertedcursor" />
            <div className="cover" id="content">
                <div className="container">
                    <Header />
                    <Component {...pageProps} />
                    <Footer />
                </div>
            </div>
        </>
    )
}

export default MyApp
