import { LinkType } from '@Types'

export const LINKS: LinkType[][] = [
    [
        {
            title: 'about',
            url: `${process.env.NEXT_PUBLIC_ORIGIN_URL}/`,
            id: '1',
        },
        {
            title: 'reviews',
            url: `${process.env.NEXT_PUBLIC_ORIGIN_URL}/#reviews`,
            id: '2',
        },
        {
            title: 'speakers',
            url: `${process.env.NEXT_PUBLIC_ORIGIN_URL}/#speakers`,
            id: '3',
        },
    ],
    [
        {
            title: 'instagram',
            url: 'https://www.instagram.com/dark.flowmapp/',
            id: '4',
            blank: true,
        },
        {
            title: 'twitter',
            url: 'https://twitter.com/dark_flowmapp',
            id: '5',
            blank: true,
        },
        {
            title: 'Medium',
            url: 'https://medium.com/@FlowMapp',
            id: '6',
            blank: true,
        },
    ],
    [
        {
            title: 'flowmapp blog',
            url: 'https://www.flowmapp.com/blog',
            blank: true,
            id: '7',
        },
        { title: 'write to us', url: 'mailto:mike@flowmapp.com', id: '8' },
    ],
]
