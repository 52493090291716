import React, { useState } from 'react'
import cx from 'classnames'
import { useDelayUnmount } from '@Utils/delayUnmount'
import styles from './About.module.scss'
import { Button } from '@Components/Button/Button'

export const About: React.FC = () => {
    const [isRendered, setisRendered] = useState(false)
    const shouldShowAboutBlock = useDelayUnmount(isRendered, 250)

    const handleAboutClick = () => {
        setisRendered(true)
    }

    const closeAboutBlock = (e: React.MouseEvent) => {
        e.stopPropagation()
        setisRendered(false)
    }

    return (
        <>
            <div className={styles.about} onClick={handleAboutClick}>
                <h4>ABOUT</h4>
            </div>
            {shouldShowAboutBlock && (
                <div
                    className={cx(styles.aboutBlockWrapper, {
                        [styles.showAbout]: isRendered,
                    })}
                    onClick={handleAboutClick}
                >
                    <div className={styles.titleAndCloseButton}>
                        <div>
                            <h4 className={styles.aboutTitle}>ABOUT</h4>
                        </div>
                        <div>
                            <Button
                                className={styles.closeButton}
                                onClick={closeAboutBlock}
                            >
                                [CLOSE]
                            </Button>
                        </div>
                    </div>
                    <caption className={styles.aboutContentWrapper}>
                        Nowadays the web suggests you tons of designs, around
                        10% of which could be called brilliant, and only 1% of
                        those ten are recognizable and famous. Dark Archive is a
                        non-profit project with the mission of finding,
                        collecting and promoting great design ideas.
                        <br /> <br />
                        Our selectees are famous designers who gave life to
                        works that we encounter daily. These experts will tell
                        us what influenced their creativity and helped in
                        shaping their own style, what helps them now to wake up
                        every morning, full of inspiration and energy to bring
                        amazing pieces into being. <br />
                        <br />
                        Project curated by FlowMapp Team
                    </caption>
                </div>
            )}
        </>
    )
}
