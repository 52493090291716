import React, { useState } from 'react'
import cx from 'classnames'
import { Social } from '@Components/Social/Social'
import { useDelayUnmount } from '@Utils/delayUnmount'
import { SocialSize } from '@Types'
import styles from './Header.module.scss'
import { About } from '@Components/Layout/Header/About/About'
import { Button } from '@Components/Button/Button'
import { MobileMenu } from '@Components/Layout/Header/MobileMenu/MobileMenu'
import Link from 'next/link'

export const Header: React.FC<{ links?: string[] }> = ({ links }) => {
    const [isRendered, setIsRendered] = useState(false)
    const shouldShowMobileMenu = useDelayUnmount(isRendered, 250)

    const handleMenuClick = () => {
        setIsRendered(!isRendered)
    }

    return (
        <div
            className={cx(styles.header, {
                [styles.headerMobile]: shouldShowMobileMenu,
            })}
        >
            <div className={styles.headerWrapper} id="header">
                <div className={styles.navBar}>
                    <div className={styles.leftBar}>
                        <div className={styles.logo}>
                            <a
                                href="https://www.flowmapp.com"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <h5>
                                    by flowmapp <span>&nbsp; ®</span>
                                </h5>
                            </a>
                        </div>
                        <About />
                    </div>
                    <div className={styles.platformDescription}>
                        <h4>
                            Collection of outstanding designs, carefully
                            selected by well-known professionals
                        </h4>
                    </div>
                    <div className={styles.socials}>
                        <Social
                            size={SocialSize.Small}
                            link="https://twitter.com/dark_flowmapp"
                        >
                            <img src="/img/Header/twitter.svg" alt="Twitter" />
                        </Social>
                        <Social
                            size={SocialSize.Small}
                            link="https://www.instagram.com/dark.flowmapp/"
                        >
                            <img
                                src="/img/Header/instagram.svg"
                                alt="Instagram"
                            />
                        </Social>
                    </div>
                    <div className={styles.burgerButtonWrapper}>
                        <Button onClick={handleMenuClick}>
                            <span className={styles.menuIcon}>[MENU]</span>
                        </Button>
                    </div>
                </div>
                <div className={styles.title}>
                    <Link href="/">
                        <a>
                            <img
                                className={styles.logoImage}
                                src="/img/Header/logo.svg"
                                alt="Dark.Archive"
                            />
                        </a>
                    </Link>
                </div>
            </div>
            <MobileMenu
                isRendered={isRendered}
                setisRendered={setIsRendered}
                shouldShowMobileMenu={shouldShowMobileMenu}
            />
        </div>
    )
}
